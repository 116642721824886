import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { Container } from "./layoutComponents";
import Logo from "../images/logo.svg";
import { Avatar } from "./Avatar";

const Header = ({ siteTitle, className }) => (
  <header className={className}>
    <Container>
      <div className="logo-wrapper">
        <Link to="/">
          <Logo className="logo" />
        </Link>
        <div>
          <h2>vacay</h2>
          <span>Plan your leaves for the year in minutes</span>
        </div>
        <Avatar />
      </div>
    </Container>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

const StyledHeader = styled(Header)`
  padding: 1rem 0;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--background-color);
  z-index: 3;
  border-bottom: 1px solid #d8d8d8;

  h2 {
    color: var(--primary-color);
    margin-bottom: 0.3rem;
    font-weight: 500;
  }

  span {
    opacity: 0.7;
    font-size: 0.8rem;
  }

  .logo-wrapper {
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    align-items: center;
    grid-gap: 1.5rem;

    .logo {
      height: 4rem;
      color: var(--primary-color);
    }
  }
`;

export default StyledHeader;
