import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Button } from "./Button";
import { useInstall } from "./useInstall";
import windowCheck from "./windowCheck";

const SideModal = styled.div`
  display: block;
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  will-change: transform;
  transition: all 0.3s ease-in-out;
  transform: translateY(${props => (props.show ? "0" : "105%")});
  background-color: var(--background-color);
  box-shadow: inset 0 0 10px #c1c1c1a1;
  border-radius: 0.6rem;
  padding: 2rem 2rem;

  display: grid;
  grid-gap: 1rem;
  z-index: 4;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #ffffffc7;
`;

const Menu = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    a {
      line-height: 2rem;
      display: block;
    }
  }
`;

const InstallTab = () => {
  const [prompt, promptToInstall] = useInstall();

  const handleClick = async () => {
    try {
      promptToInstall();

      const choiceResult = await prompt.userChoice;
      if (choiceResult.outcome !== "accepted") {
        throw new Error("The install has been rejected");
      }

      if (typeof window !== "undefined") {
        windowCheck() &&
          window.alert(
            "Thanks for installing the app 😊. If you like this app, please do share it as well"
          );
      }
    } catch (error) {
      console.error(error);
    }
  };

  return prompt ? (
    <li onClick={handleClick}>
      <span>Install app</span>
    </li>
  ) : (
    <></>
  );
};

const LoginModal = ({
  showModal,
  setShowModal,
  handleLogin,
  isLoggedIn,
  handleLogoutClick,
  uid
}) => {
  const modalRef = useRef();
  const [loading, setLoading] = useState(false);
  const handleOutsideModalClick = event => {
    if (!!modalRef.current) {
      if (!modalRef.current.contains(event.current)) {
        setShowModal(false);
      }
    }
  };

  useEffect(() => {
    if (!!showModal) {
      document.addEventListener("click", handleOutsideModalClick);
    } else {
      document.removeEventListener("click", handleOutsideModalClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideModalClick);
    };
  }, [showModal]);

  const handleLoginClick = async () => {
    try {
      setLoading(true);

      await handleLogin();
      setShowModal(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleShare = async () => {
    try {
      if (!navigator.share) {
        return;
      }
      await navigator.share({
        title: "vacay - holiday planner",
        text: "Hey, checkout vacay app for planning holidays easily",
        url: `https://vacay.workofutkarsh.com/${uid ? `?ref=${uid}` : ``}`
      });
      console.log("Successful share");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {showModal && <Overlay />}
      <SideModal show={showModal} ref={modalRef}>
        <Menu>
          {/* <li>
                <a>
                  <span> ✅ Toggle Email Notifications</span>{" "}
                </a>
              </li> */}
          <li>
            <a href="https://twitter.com/BhimteBhaisaab" target="__blank">
              <span>About the developer</span>
            </a>
          </li>
          {windowCheck() && <InstallTab />}
          {windowCheck() && window.navigator.share && (
            <li>
              <a onClick={handleShare}>
                <span>Share app</span>
              </a>
            </li>
          )}
        </Menu>

        {!isLoggedIn ? (
          <>
            <span>
              Enable leave reminder by signing up with your Google Account
            </span>
            {loading ? (
              <span style={{ textAlign: "center" }}>Loading...</span>
            ) : (
              <Button onClick={handleLoginClick}>Login</Button>
            )}
          </>
        ) : (
          <>
            <Button onClick={handleLogoutClick}>Logout</Button>
          </>
        )}
      </SideModal>
    </>
  );
};

export default LoginModal;
