import React, { useState, useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import { FirebaseContext } from "gatsby-plugin-firebase";

import HamburgerIcon from "../images/menu.svg";
import LoginModal from "./LoginModal";
import windowCheck from "./windowCheck";

const Container = styled.a`
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  display: grid;
  place-items: center;

  svg {
    color: var(--primary-color);
    && {
      height: 2rem;
    }
  }
`;

let userCache = windowCheck() && localStorage.getItem("user-data");
userCache = (userCache && JSON.parse(userCache)) || null;

export const Avatar = () => {
  const [showModal, setShowModal] = useState(false);
  const [user, setUser] = useState(userCache);
  const firebase = useContext(FirebaseContext);

  const toggleModal = state => setShowModal(state);

  useEffect(() => {
    if (!!user) {
      windowCheck() && localStorage.setItem("user-data", JSON.stringify(user));
    }
  }, [user]);

  const handleLogin = async () => {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      firebase.auth().useDeviceLanguage();
      const result = await firebase.auth().signInWithPopup(provider);

      const user = result.user.providerData[0];
      console.log("TCL: handleLogin -> user", user);

      setUser(user);
    } catch (error) {
      console.error(error);
    }
  };

  const handleLogoutClick = async () => {
    try {
      setUser(null);
      windowCheck() && localStorage.removeItem("user-data");
      await firebase.auth().signOut();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container>
      {user ? (
        <img
          onClick={toggleModal}
          src={user.photoURL}
          alt={user.displayName}
          className="avatar"
        />
      ) : (
        <div>
          <HamburgerIcon onClick={toggleModal} className="avatar" />
        </div>
      )}
      <LoginModal
        isLoggedIn={!!user}
        handleLogin={handleLogin}
        handleLogoutClick={handleLogoutClick}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </Container>
  );
};
