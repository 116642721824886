import styled from "styled-components";
export const Button = styled.button`
  border-radius: 0.6rem;
  background-color: var(--primary-color);
  outline: none;
  border: none;
  color: white;
  line-height: 2rem;
  padding: 0 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-size: 0.8rem;

  &:hover,
  &:active {
    background-color: #8892ff;
  }
`;
