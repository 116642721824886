import React from "react";
import windowCheck from "./windowCheck";

export const useInstall = () => {
  const [prompt, setState] = React.useState();

  const promptToInstall = () => {
    if (prompt) {
      return prompt.prompt();
    }
    return Promise.reject(
      new Error(
        'Tried installing before browser sent "beforeinstallprompt" event'
      )
    );
  };

  React.useEffect(() => {
    const ready = e => {
      e.preventDefault();
      setState(e);
    };

    windowCheck() && window.addEventListener("beforeinstallprompt", ready);

    return () => {
      windowCheck() && window.removeEventListener("beforeinstallprompt", ready);
    };
  }, []);

  return [prompt, promptToInstall];
};
